import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"

import MetaWp from "../components/meta-wp"
import Header from "../components/header"
import Post from "../components/post"
import PostListing from "../components/post-listing"

const PostListingWrap = styled.div`
  margin-top: ${rem(80)};

  @media ${props => props.theme.mediumDown} {
    margin-top: ${rem(40)};
  }
`

const PostTemplate = ({ data }) => {
  const { wp, metaWp, allPosts, backgroundImage } = data

  return (
    <React.Fragment>
      <MetaWp wp={metaWp} />

      <Header />

      <Post data={wp} />

      <PostListingWrap>
        <PostListing
          data={{
            published: true,
            sectionHeading: `More News`,
            limit: 4,
            backgroundImage: { localFile: backgroundImage },
            cta: [
              {
                title: `All news`,
                url: `/news`,
              },
            ],
          }}
          allPosts={allPosts}
        />
      </PostListingWrap>
    </React.Fragment>
  )
}

PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PostTemplate

export const pageQuery = graphql`
  query($id: String!) {
    ...MetaWpPost

    ...AllPostsFragment

    wp: wordpressPost(id: { eq: $id }) {
      ...PostNodeFragment
    }

    backgroundImage: file(relativePath: { eq: "post-listing-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
