import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"
import Img from "gatsby-image/withIEPolyfill"
import { Location } from "@reach/router"

import Link from "./link"

import { ReactComponent as SvgFacebook } from "../assets/images/icons/icon-facebook.svg"
import { ReactComponent as SvgTwitter } from "../assets/images/icons/icon-twitter.svg"

const Container = styled.article``

const Header = styled.header`
  ${props => props.theme.gridContainer()}

  margin-top: ${rem(40)};
  margin-bottom: ${rem(40)};
  text-align: center;

  @media ${props => props.theme.mediumDown} {
    margin-top: ${rem(20)};
    margin-bottom: ${rem(40)};
  }

  > div {
    max-width: ${rem(640)};
    margin: 0 auto;
  }
`

const Date = styled.time.attrs({ className: `styled-h3` })`
  margin-bottom: ${rem(10)};
  display: block;
  color: ${props => props.theme.colorYellow};
`

const Title = styled.h1.attrs({ className: `styled-h2` })``

const Categories = styled.ul`
  margin-top: ${rem(5)};
  font-size: ${rem(14)};

  @media ${props => props.theme.mediumDown} {
    font-size: ${rem(12)};
  }

  li {
    display: inline-block;
    position: relative;
    padding: 0 ${rem(6)};

    &:not(:last-child) {
      margin-right: ${rem(6)};

      &::after {
        content: "";
        width: 2px;
        height: 1.4em;
        position: absolute;
        top: 0;
        right: ${rem(-3)};
        background-color: currentColor;
        opacity: 0.3;
        transform: rotate(15deg);
      }
    }
  }

  a {
    opacity: 0.7;

    &:hover {
      opacity: 1;
      color: ${props => props.theme.colorTurquoise};
    }

    &:active {
      opacity: 0.5;
    }
  }
`

const Image = styled.figure`
  ${props => props.theme.gridContainer()}

  margin-bottom: ${rem(40)};

  @media ${props => props.theme.mediumDown} {
    margin-bottom: ${rem(20)};
  }

  > div {
    ${props => props.theme.gridCell(12)}

    margin: 0 auto;

    @media ${props => props.theme.xxlargeUp} {
      ${props => props.theme.gridCell(8)}
    }
  }
`

const Inner = styled.div`
  ${props => props.theme.gridContainer()}
  ${props => props.theme.gridGrid()}
`

const Sidebar = styled.aside`
  ${props => props.theme.gridCell(12)}

  margin-left: auto;

  @media ${props => props.theme.xxlargeUp} {
    ${props => props.theme.gridCell(1)}

    margin-top: initial;
  }

  ul {
    display: flex;
    justify-content: center;

    li {
      height: ${rem(40)};
      width: 100%;
      max-width: ${rem(40)};
      margin: ${rem(5)};
      cursor: pointer;
    }

    @media ${props => props.theme.xxlargeUp} {
      flex-direction: column;
      justify-content: flex-start;

      li {
        margin-left: 0;
      }
    }
  }
`

const Main = styled.div`
  ${props => props.theme.gridCell(12)}

  margin-top: ${rem(20)};
  margin-right: auto;

  @media ${props => props.theme.xxlargeUp} {
    ${props => props.theme.gridCell(7)}

    margin-top: 0;
  }
`

const Content = styled.div`
  > p:first-child {
    font-size: ${rem(22)};

    @media ${props => props.theme.xxlargeDown} {
      font-size: ${rem(20)};
    }

    @media ${props => props.theme.smallDown} {
      font-size: ${rem(18)};
    }
  }

  div.aligncenter {
    text-align: center;
    width: 100% !important;
  }

  div.wp-caption .wp-caption-text {
    font-size: ${rem(15)};
    opacity: 0.75;
    padding-top: ${rem(8)};
  }

  img.aligncenter {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
`

const Post = ({
  data: {
    date,
    title,
    content,
    categories,
    acf: { image },
  },
}) => {
  return (
    <Container>
      <Header>
        <div>
          <Date dangerouslySetInnerHTML={{ __html: date }} datetime={date} />

          <Title dangerouslySetInnerHTML={{ __html: title }} />

          {categories && categories.length > 0 && (
            <Categories aria-label="Categories">
              {categories.map((cat, i) => (
                <li key={i}>
                  <Link
                    to={`/news/category/${cat.slug}`}
                    dangerouslySetInnerHTML={{ __html: cat.name }}
                    title="Category"
                  />
                </li>
              ))}
            </Categories>
          )}
        </div>
      </Header>

      {image && image.localFile && (
        <Image>
          <Img fluid={image.localFile.childImageSharp.fluid} alt={title} />
        </Image>
      )}

      <Inner>
        <Sidebar>
          <Location>
            {({ location }) => (
              <ul>
                <li>
                  <Link
                    data-track-click
                    data-track-click-ga-category="Post"
                    data-track-click-ga-action="Share on Facebook"
                    data-track-click-ga-label={title}
                    target="_blank"
                    rel="noopener noreferrer"
                    to={`https://www.facebook.com/sharer/sharer.php?u=${location.href}`}
                  >
                    <SvgFacebook />
                  </Link>
                </li>
                <li>
                  <Link
                    data-track-click
                    data-track-click-ga-category="Post"
                    data-track-click-ga-action="Share on Twitter"
                    data-track-click-ga-label={title}
                    target="_blank"
                    rel="noopener noreferrer"
                    to={`https://twitter.com/intent/tweet/?url=${location.href}`}
                  >
                    <SvgTwitter />
                  </Link>
                </li>
              </ul>
            )}
          </Location>
        </Sidebar>

        <Main>
          <Content
            className="styled"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </Main>
      </Inner>
    </Container>
  )
}

Post.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Post
